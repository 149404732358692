@use "../../scss/colors";
@use "../../scss/typography";

.searchBar {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  flex-shrink: 0;
  height: 4.5rem;
  background-color: white;
  border-bottom: 1px solid colors.$greyNew5;

  .input {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    @include typography.title-light;
    line-height: 1rem;
    color: colors.$greyNew2;
    border: transparent;
    &:focus {
      outline: none;
      ::placeholder {
        color: colors.$greyNew5;
      }
    }
    ::placeholder {
      color: colors.$greyNew4;
    }
  }
}
