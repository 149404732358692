@use "../../scss/colors";
@use "../../scss/typography";

.footer {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    border-top: 1px solid colors.$greyNew5;
    padding: 1.25rem 1rem;

    .loader {
        color:white;
        margin-right: 0.5rem;
    }
}

.form {
    .fields {
        padding : 1.5rem 1rem;
        overflow-y: auto;
        .field {
            padding-top:0.5rem;
            padding-bottom:0.5rem;
        }
    }
}