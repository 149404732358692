@use "../../scss/colors";
@use "../../scss/typography";

%containers {
    width:50%;
    height:100%;
    border-right : 1px solid colors.$greyNew5;
    position: relative;
}

.templatePage {
    width:100%;
    height:100%;
    display: flex;
    color : colors.$black;
    .leftContainer {
        @extend %containers;
    }
    .rightContainer{
        @extend %containers;
        overflow-y: auto;
    }
    .fieldsContainer {
        height:100%;
        padding-bottom:6rem;
        overflow-y: auto;
    }

}