$blue1: #103a56;
$blue2: #175178;
$blue3: #1d689a;
$blue4: #247fbc;
$blue5: #3195d8; // primary
$blue6: #54a7de;
$blue7: #76b8e5;
$blue8: #98caeb;
$blue9: #bbdcf2;
$blue10: #bcdefb;
$blue11: #ecf8ff;
$blue12 : #EAF4FB;

$grey1: #5c5c5c;
$grey2: #707070;
$grey3: #858585;
$grey4: #999999;
$grey5: #adadad;
$grey6: #c2c2c2;
$grey7: #d6d6d6;
$grey8: #ebebeb;
$grey9: #fcfcfc;
$grey10: #f2f2f2;
$grey11: #333333;

$greyNew1: #4e545d;
$greyNew2: #757e8b;
$greyNew3: #9ca8ba;
$greyNew4: #ced4dd;
$greyNew5: #e6e9ee;
$greyNew6: #f5f6f8;
$greyNew7: #fafbfb;


$yellow:#FFC01C;
$danger : #DC2A2A;
$black : #333333