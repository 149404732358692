@use "../../scss/colors";
@use "../../scss/typography";
@use "../../scss/transitions";


.tagsInput {
  ul {
    list-style: none;
    padding: 0;
    margin : 0
  }
  border: 1px solid transparent;
  margin-top: 0.5rem;
  color:colors.$greyNew3;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  @include transitions.transition-all;
  &.focussed{
    border: 1px solid colors.$greyNew3;
    border-radius: 0.25rem;
  }
  &:hover, &:focus{
    border: 1px solid colors.$greyNew3;
    border-radius: 0.25rem;
  }
  .input {
    border-radius: 0.25rem;
    @include typography.body-light;
    border: 0;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    margin-left: 0.5rem;
    background-color: transparent;
    color:colors.$black;
    &:focus {
      box-shadow: none;
      outline: 0 ;
      border: 0; 
    }
  }
  .tagIcon {
    margin : 0.75rem 0.875rem;
  }
  .tag {
    background-color: colors.$greyNew5;
    color:colors.$black;
    @include typography.body-light;
    border: 0.25rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    &:hover {
      background-color: colors.$greyNew4;
    }
  }
  .remove {
      padding-left: 0.5rem;
      cursor: pointer;
      color: colors.$black
  }
}

.autoSuggest {
  display: inline;
}


.suggestion{
  &:hover{
    background-color: colors.$blue11;
  }
  padding:  0.25rem 0.5rem;
  border-radius: 0.25rem;
  width:10rem
}

.suggestionsContainerOpen {
  position: absolute;
  background-color: white;
  padding: 0.5rem 0.25rem;
  border : 1px solid colors.$greyNew5;
  color:colors.$black;
  border-radius: 0.25rem;
}