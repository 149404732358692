@use "../../scss/colors";
@use "../../scss/typography";
@use "../../scss/shadows";


.templateCard {
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    border : 1px solid colors.$greyNew5;
    &:hover {
        @include shadows.shadow;
        border-color: colors.$greyNew4;
    }
    .title{
        @include typography.subheader-heavy
    }
    .image {
        margin: 1rem auto;
    }
    .desc {
        @include typography.body-light;
        margin-top :0.5rem;

    }
}