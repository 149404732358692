@use "../../scss/colors";
@use "../../scss/typography";
@use "../../scss/transitions";

.templateSelector {
  position: relative;
  padding: 1rem 1rem 0 1rem;
  @include transitions.transition-all;
  .dropdownContainer {
    position: relative;
  }
  .button {
    border: 1px solid colors.$greyNew3;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.75rem;
    text-align: left;
    background-color: colors.$greyNew6;
    @include transitions.transition-all;
    &.isOpen {
      border-radius: 0.25rem 0.25rem 0 0;
      border-bottom: transparent;
    }
  }
  .list {
    max-height: 50vh;
    overflow-y: auto;
    border: 1px solid colors.$greyNew3;
    border-radius: 0 0 0.25rem 0.25rem;
    position: absolute;
    border-top: transparent;
    background-color: white;
    list-style: none;
    padding: 0;
    width:100%;
    .item {
      padding: 0.375rem 0.75rem;
      &:hover {
        background-color: colors.$blue11;
      }
      &:last-child {
        padding-bottom: 0.75rem;
      }
    }
  }
}

.listItem {
  display: flex;
  align-items: center;
  .textContainer {
    margin-left: 0.75rem;
  }
  .title {
    @include typography.body-heavy;
  }
  .desc {
    @include typography.body-light;
  }
  .arrow {
    margin-left: auto;
    color: colors.$greyNew3;
  }
}
