@use "../../scss/colors";
@use "../../scss/typography";
@use "../../scss/breakpoints";

.templateGroup {
  margin-top:  1.5rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap : 1.5rem;
  color : colors.$black;
  @include breakpoints.respond-to("medium") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include breakpoints.respond-to("large") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include breakpoints.respond-to("x-large") {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @include breakpoints.respond-to("2x-large") {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
