@use "../../scss/colors";
@use "../../scss/typography";

.placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color : colors.$greyNew3;
    background : colors.$greyNew7;
    height:calc(100vh - 72px);
    .text {
        margin-top: 1rem;
        text-align: center;
        @include typography.body-light;
    }
}