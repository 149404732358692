.loaderContainer{
    display: flex;
    width: 100%;
    height:100%;
    align-items: center;
    justify-content: center;

}
.loader {
    width : 2rem;
    height : 2rem;
}