@use "../../scss/shadows";
@use "../../scss/colors";

.menu{
    position: relative;
    display: inline-block;
    text-align: left;
    .menuItems {
        position: absolute;
        right: 0;
        margin-top: 0.5rem;
        transform-origin: top right;
        border-radius: 0.5rem;
        background-color: white;
        min-width: 10rem;
        padding: 0.5rem 0.375rem;
        @include shadows.shadow-lg;

        .item {
            border-radius: 0.25rem;
            width :100%;
            color : colors.$greyNew2;
            padding: 0.25rem 0.375rem;
            text-align: left;
            color:colors.$black;
            background-color: transparent;
            border : 0;
            +.item {
                margin-top : 8px;
            }
            &:hover {
                background-color: colors.$greyNew6;
            }
            &.delete {
                color : colors.$danger
            }
        }
    }
}