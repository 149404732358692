@use "../../scss/variables";
@use "../../scss/transitions";

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: none;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.25rem;
  background-color: transparent;
  background-image: none;
  border : transparent;
  line-height: inherit;
  color: inherit;
  @include transitions.transition-all;
  &:hover {
    @include variables.shadow;
    filter: brightness(0.9);
  }
  + .actionButton {
    margin-left: 0.25rem;
  }
}