@use "../../scss/colors";
@use "../../scss/typography";
@use "../../scss/shadows";

.pagination {
  display: flex;
  align-items: center;
  .text {
    @include typography.body-heavy;
  }
  .button {
    border-radius: 0.25rem;
    @include shadows.shadow;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid colors.$greyNew3;
    padding: 0.5rem 0.75rem;
    min-width: 3rem;
    min-height: 1.5rem;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
