@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

  @font-face {
    font-family: Inter;
    font-weight: 100;
    src: url("https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap");
  }

  @font-face {
    font-family: Inter;
    font-weight: 300;
    src: url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
  }

  @font-face {
    font-family: Inter;
    font-weight: 400;
    src: url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
  }

  @font-face {
    font-family: Inter;
    font-weight: 500;
    src: url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
  }

  @font-face {
    font-family: Inter;
    font-weight: 700;
    src: url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
  }
}

.pro-sidebar {
  z-index: 15 !important
}

#root {
  height: 100vh;
  color: #333333;
}

body {
  @apply font-body;
}