@use "../../scss/colors";
@use "../../scss/typography";

.dashboard {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  background-color: colors.$greyNew7;
  color : colors.$black;
  .title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    @include typography.display-heavy;
  }
}

.groupContainer {
  &:not(:first-child) {
    padding-top : 1rem
  }
}