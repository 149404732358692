@use "../../scss/variables";

.labelContainer {
  display: flex;
  justify-content: space-between;
  label {
    @include variables.body-light;
    color: variables.$black;
    line-height: 24px;
    letter-spacing: 0.25px;
    sup{
      color: variables.$greyNew3;
    }
  }
  .count {
    @include variables.body-light;
    color: variables.$greyNew3;
  }
}

.field {
  width: 100%;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  border: 1px solid variables.$greyNew3;
  &:focus {
    border-color: transparent !important;
    box-shadow : 0 0 0 1pt variables.$blue5;
  }
  outline: none;
  @include variables.body-light;
  padding: 0.625rem 0.75rem;
}
