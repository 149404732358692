@use "../../scss/colors";

.modal {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 50;
  overflow-y: auto;
  .modalContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    .modalContent {
      z-index: 60;
      border-radius: 0.25rem;
      position: relative;
      background-color: white;
      border: 4px;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      padding:1rem;
      &.smallModal{
        width : 500px
      }
    }

  }
}

.modalOverlay {
  z-index: 10;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.3;
}
