@use "../../scss/colors";
@use "../../scss/typography";


.tabs {
    position: sticky;
    top:0;
    border-bottom: 1px solid colors.$greyNew5;
}

.cancelButton {
    margin : 0.875rem 0.5rem 0.875rem auto;
}