@use "../../scss/colors";
@use "../../scss/typography";

.item {
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid colors.$greyNew4;
  background-color: white;
  @include typography.body-light;
  &.isNew {
    background-color: #F4FAFD;
  }

  .topBar {
    align-items: center;
    margin-bottom: 0.5rem;

    .time {
      @include typography.body-light;
    }
  }
  .textContainer {
    display: flex;
    align-items: flex-start;
    .actionGroup {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: colors.$greyNew3;
    }
  }
  .lowerContainer {
    display: flex;
    align-items: center;
    .source {
      margin-left: 0.75rem;
      @include typography.body-light;
      color: colors.$greyNew3;

    }
  }
  .text {
    cursor: pointer;
    white-space: pre-wrap;
    line-height: 1.5rem;
    @include typography.body-light;
    font-family: Inter;
    letter-spacing: 0.25px;
    flex-grow: 1;
    font-family: inherit;
  }
}
