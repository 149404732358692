@use "../../scss/variables";
@use "../../scss/transitions";

.button {
  border: 1px transparent solid;
  border-radius: 0.25rem;
  letter-spacing: 0.025rem;
  position: relative;
  @include transitions.transition-all;
  background-color: white;
  padding: 0;
  line-height: inherit;
  color: inherit;
  &:hover {
    filter : brightness(1.1)
  }
  &:disabled {
    opacity: 0.5;
  }
  &.primary {
    background-color: variables.$blue5;
    color: white;
  }
  &.cancel {
    border-color: variables.$greyNew3 !important;
    color: variables.$greyNew3;
  }
  &.small {
    padding : 0.625rem 1rem;
    @include variables.body-heavy

  }
  &.default {
    padding : 0.625rem 1rem;
    @include variables.body-heavy

  }
  &.large {
    padding : 0.75rem 1rem;
    @include variables.body-heavy
  }
  &.isLoading{
    color:transparent;
    &::after{
      content : "";
      position:absolute;
      top : 0;
      left : 0;
      right : 0;
      bottom: 0;
      margin:auto;
      width : 20px;
      height:20px;
      border : 4px solid transparent;
      border-top-color: white ;
      border-radius: 50%;
      animation: spin 1s ease infinite;
    }
  }
  @keyframes spin {
    from {
      transform : rotate(0turn);
    }
    to {
      transform : rotate(1turn);
    }
  }
}
