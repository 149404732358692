@mixin display-heavy {
  font-size: 2.125rem;
  font-weight: 600;
}
@mixin display-light {
  font-size: 2.125rem;
  font-weight: 300;
}
@mixin headline-heavy {
  font-size: 1.5rem;
  font-weight: 600;
}
@mixin headline-light {
  font-size: 1.5rem;
  font-weight: 300;
}
@mixin title-heavy {
  font-size: 1.25rem;
  font-weight: 600;
}
@mixin title-light {
  font-size: 1.25rem;
  font-weight: 300;
}
@mixin subheader-heavy {
  font-size: 1rem;
  font-weight: 600;
}
@mixin subheader-light {
  font-size: 1rem;
  font-weight: 400;
}
@mixin body-heavy {
  font-size: 0.875rem;
  font-weight: 700;
}
@mixin body-light {
  font-size: 0.875rem;
  font-weight: 400;
}
@mixin caption-heavy {
  font-size: 0.75rem;
  font-weight: 600;
}
@mixin caption-light {
  font-size: 0.75rem;
  font-weight: 300;
}
@mixin small-heavy {
  font-size: 0.625rem;
  font-weight: 700;
}
@mixin small-light {
  font-size: 0.625rem;
  font-weight: 400;
}
