@use "../../scss/colors";
@use "../../scss/typography";


.contentPage {
    width:100%;
    height:100%;
    overflow-y: auto;
    background-color: colors.$greyNew7;
    color : colors.$black;
    .title {
        padding-left : 1rem;
        margin-top : 1rem;
        @include typography.display-heavy
    }
    .pagination {
        width: 100%;
        display: flex;
        margin-bottom: 1rem;
        justify-content: flex-end;
    }
}