@use "../../scss/shadows";
@use "../../scss/colors";
@use "../../scss/typography";

.modal {
  color: colors.$greyNew2;
}

.menuBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    @include typography.title-heavy;
    color: colors.$black;
  }
}

.subText {
  margin-top: 0.5rem;
  @include typography.subheader-light;
}

.menuButton {
  width: 1.75rem;
  height: 1.75rem;
  &.open {
    background-color: colors.$greyNew5;
  }
  border-radius: 0.25rem;
  margin-left: 0.25rem;
  &:hover {
    @include shadows.shadow;
  }
}

.inputContainer {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.textContainer {
  margin-top: 0.75rem;
  @include typography.body-light;
  textArea {
    color: colors.$black;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid colors.$greyNew3;
    &:focus {
      border-color: colors.$blue5;
    }
  }
}


.buttonContainer{
  display: flex;
  justify-content: flex-end;
  margin-top:1rem;
  button{
    width : 150px;
    +button{
      margin-left: 1rem;
    }
  }
}