@use "../../scss/shadows";
@use "../../scss/colors";
@use "../../scss/typography";

.modal {
  color: colors.$greyNew2;
}

.menuBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    @include typography.subheader-heavy;
    color: colors.$black;
  }
  .actionGroup {
    display: flex;
  }
}

.menuButton {
  width: 1.75rem;
  height: 1.75rem;
  border:transparent;
  background-color: transparent;
  color: inherit;
  &.open {
    background-color: colors.$greyNew5;
  }
  border-radius: 0.25rem;
  margin-left: 0.25rem;
  &:hover {
    @include shadows.shadow;
  }
}

.inputContainer {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.textContainer {
  margin-top: 0.75rem;
  @include typography.body-light;
  .textArea {
    color: colors.$black;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid colors.$greyNew3;
    padding: .5rem .75rem;
    font-family: inherit;
    &:focus, &:focus-visible{
      border-color: transparent !important;
      box-shadow : 0 0 0 1pt colors.$blue5;
      outline: 0 0 0 1pt colors.$blue5;;
    }
  }
}
.inputs {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.5rem;
  padding: 0.25rem 0;
  @include typography.body-light;
  .key {
    grid-column: span 1 / span 1;
    padding: 0.25rem 0.5rem 0.25rem 0;
  }
  .value {
    grid-column: span 3 / span 3;
    padding: 0.25rem;
    padding-right: 0;
    .text {
      display: inline-block;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      outline: none;
      background-color: colors.$greyNew6;
      border-radius: 0.25rem;
      &:hover {
        background-color: colors.$greyNew5;
        color: colors.$greyNew1;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
