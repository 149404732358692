@use "../../scss/colors";
@use "../../scss/typography";

.list {
  display: flex;
  background-color: white;
  z-index: 5;
  color: #5c5c5c;
  .tab {
    @include typography.body-heavy;
    padding: 0.625rem 0.75rem;
    margin: 0.875rem 0.5rem;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    &:hover {
      background-color: colors.$greyNew6;
    }
    &.selected {
      border: none;
      color: colors.$blue5;
      background-color: colors.$blue12;
    }
  }
}
