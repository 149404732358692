$breakpoints: (
  'small':  ( min-width:  400px ),
  'medium': ( min-width:  767px ),
  'large':  ( min-width: 1023px ),
  'x-large':  ( min-width: 1279px ),
  '2x-large':  ( min-width: 1535px ),
) !default;

@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
      // Prints a media query based on the value
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }
   
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }