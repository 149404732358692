@use "../../scss/colors";
@use "../../scss/typography";

.imageContainer {
  border-radius: 0.25rem;
  margin-right: 0.5rem;

  flex-shrink: 0;
  .image {
    width: 4rem;
    height: 4rem;
    &.small {
      width: 3rem;
      height: 3rem;
    }
  }
}
