@use "../../scss/colors";
@use "../../scss/breakpoints";
@use "../../scss/typography";

.contentCard {
  position: relative;
  padding : 1.25rem 1rem;
  border-radius: 4px;
  border: 1px solid colors.$greyNew4;
  background-color: white;
  &:hover {
    border: 1px solid colors.$greyNew3;
  }
  .titleButton {
    background-color: transparent;
    border : 0;
    color : colors.$greyNew3;
    padding:0;
  }
  @include typography.body-heavy;
  @include breakpoints.respond-to("small") {
      color:colors.$black;
  }
  .topBar {
      display: flex;
      justify-content: space-between;
      color : colors.$greyNew3;
      align-items: center;
      .actionGroup {
        display: flex;
      }
  }
  .text {
    margin-top : 0.5rem;
    cursor: pointer;
    white-space: pre-wrap;
    @include typography.body-light;
    font-family: Inter;
  }
}
